<template>
  <module-container :title="$t(sTitle)" path="users" show-toolbar>
    <template #route>
      <router-view />
    </template>

    <template #filters>
      <users-filters v-if="showFilters" :key="`${$route.fullPath}.filters`" />
    </template>
  </module-container>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import UsersFilters from "@/modules/users/components/UsersFilters.vue";
import PaginateProviderMixin from "@/mixins/PaginateProviderMixin";

@Component({
  components: { UsersFilters },
})
export default class UsersIndex extends Mixins(PaginateProviderMixin) {
  get sTitle() {
    return this.$route.name === "users.loggedin" ? "logged.users" : "users";
  }

  get showFilters() {
    return this.$route && this.$route.name
      ? ["users.list", "users.create", "users.update"].includes(
          this.$route.name
        )
      : false;
  }
}
</script>
