<template>
  <sheet-filters
    v-model="obFilterData"
    @apply="applyFilters"
    @reset="resetFilters"
    :persistent="persistent"
    :model-name="modelClassName"
  >
    <template #default="{ apply }">
      <v-row>
        <v-col cols="4" lg="2" v-if="!hideActive">
          <v-switch
            :label="$t('active')"
            :value="1"
            v-model="obFilterData.active"
            @change="apply"
          ></v-switch>
        </v-col>
        <v-col cols="4" lg="2" v-if="showByCompany">
          <v-switch
            :label="$t('by.current.company')"
            :value="1"
            v-model="obFilterData.bycompany"
            @change="apply"
          ></v-switch>
        </v-col>
        <v-col v-bind="colsSearchField">
          <search-field v-model="obFilterData.search" @save="apply" clearable />
        </v-col>
      </v-row>
    </template>
  </sheet-filters>
</template>

<script lang="ts">
import { Component, Prop, Mixins } from "vue-property-decorator";
import SheetFilters from "@/components/form/SheetFilters.vue";
import SearchField from "@/components/form/fields/Search.vue";
import UsersMixin from "../mixins/UsersMixin";
import { AppModule } from "@/store/app";

@Component({
  components: { SheetFilters, SearchField },
})
export default class UsersFilters extends Mixins(UsersMixin) {
  obFilterData: Record<string, any> = {};
  @Prop(Boolean) readonly persistent!: boolean;
  @Prop(Boolean) readonly hideActive!: boolean;
  // @Prop(Boolean) readonly loading!: boolean;
  @Prop(Boolean) readonly preventApplyOnReset!: boolean;

  onMounted() {
    this.obFilterData = this.$_.get(AppModule.filters, this.modelClassName, {});
  }

  applyFilters() {
    this.$emit("apply", this.obFilterData);
  }

  resetFilters() {
    this.$emit("input", {});
    this.$emit("reset");
    if (this.preventApplyOnReset) {
      return;
    }

    this.applyFilters();
  }

  get colsSearchField() {
    return {
      cols: this.hideActive ? 12 : 8,
      lg: this.hideActive ? 6 : 5,
      xl: this.hideActive ? 6 : 4,
    };
  }

  get showByCompany() {
    return this.$ability.can("manage", "all");
  }
}
</script>
